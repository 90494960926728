import React from "react"
import LayoutThree from "../components/layout/main/layoutThree"


import PicContainer from "../components/picContainer/PicContainer"
import SEO from "../components/util/seo/Seo"

const TimelinePage = () => (
    <LayoutThree title="Projects" pageName="Projects" description="Ilias Zales Projekte" type="kp" image="no">

        <SEO title="Projects Ilias Zales" metaDescription='Ilias Zales Projects' />


    </LayoutThree>


)

export default TimelinePage
